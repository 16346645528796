import { useRouter } from "next/router";
import { useMemo } from "react";

import EN_US from "../../content/compiled-locales/en-US.json";
import FI_FI from "../../content/compiled-locales/fi-FI.json";
import DA_DK from "../../content/compiled-locales/da-DK.json";
import SV_SE from "../../content/compiled-locales/sv-SE.json";

export const useFormattedMessages = () => {
  const { locale } = useRouter();
  const [shortLocale] = locale ? locale.split("-") : ["en"];

  const messages = useMemo(() => {
    switch (shortLocale) {
      case "fi":
      case "fi-FI":
        return FI_FI;
      case "da":
      case "da-DK":
        return DA_DK;
      case "sv":
      case "sv-SE":
        return SV_SE;
      case "en":
      case "en-US":
        return EN_US;
      default:
        return EN_US;
    }
  }, [shortLocale]);

  return {
    locale: shortLocale,
    messages,
  };
};
